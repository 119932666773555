import {ApplicationState} from '.'
import {Action, ActionCreator} from 'redux'
import {ThunkAction} from 'redux-thunk'

const SET_TITLE = 'SET_TITLE'

export interface AppState {
  title: string
}

interface IAppAction extends Action {
  title: string
}

const defaultState: AppState = {
  title: '',
}

export const reducer = (state: AppState = defaultState, action: IAppAction): AppState =>
  action.type === SET_TITLE
    ? {
      ...state,
      title: action.title,
    }
    : state

export const setTitle: ActionCreator<ThunkAction<IAppAction, ApplicationState, void, IAppAction>> = (title: string) =>
  (dispatch, getState) => {
    if (getState().app.title !== title) {
      return dispatch({
        title,
        type: SET_TITLE,
      })
    }
    return null
  }