/* eslint-disable */
import React from 'react'
import {PixiComponent, Stage} from '@inlet/react-pixi'
import * as PIXI from 'pixi.js'
import '../../../common/extensions/pixi'
import {DailyChartModel, NavigationChartWithRange} from './DailyChartModel'
import _ from 'lodash'
import Scrollbar from 'react-scrollbars-custom'
import {time} from '../../../common/common'
import {EventData, Swipeable} from 'react-swipeable'
import {ALPHA, COLOR_NUMBERS, FIVE_MINUTES_TIME, HALF_HOUR_TIME, PANEL_WIDTH, RIGHT_PANEL_WIDTH} from './common'

const CHOSEN_EMPTY_BACKGROUND_COLOR = 0xf6f7f8
const CHOSEN_CHART_BACKGROUND_COLOR = 0xffffff
const CHOSEN_CHART_BACKGROUND_GRID_COLOR = 0xe4e4e4

const CHOSEN_CHART_HORIZONTAL_WHITE_BACKGROUND_GRID_COLOR = 0xe4e4e4
const CHOSEN_CHART_HORIZONTAL_GRAY_BACKGROUND_GRID_COLOR = 0xefeff0

const CHART_LINE_WIDTH = 3

const HORIZONTAL_DASH_WIDTH = 2
const HORIZONTAL_DASH_FILL = 6
const HORIZONTAL_DASH_SKIP = 6
const VERTICAL_DASH_WIDTH = 1
const VERTICAL_DASH_FILL = 8
const VERTICAL_DASH_SKIP = 4

interface ChosenChartCanvasProps {
  chartWidth: number
  height: number
  visibles: [boolean, boolean, boolean, boolean]
  chosenHalfHourIndex: number
  dailyChartModel: DailyChartModel
  isDemo: boolean
  isActualDay: boolean
  timeDemoSeconds: number
  nowTimeMilliseconds: number
}

const gridIndexes = [0, 1, 3, 5, 7, 9]

const gChartBackground = new PIXI.Graphics()
const gBackground = new PIXI.Graphics()
const gs = _.range(4).map(_x => new PIXI.Graphics())

// todo inefficient pixel-by-pixel drawing
const drawHorizontalDashLine = (g: PIXI.Graphics, x0: number, x1: number, y: number, dashFill: number, dashSkip: number, ranges: [number, number][]): void => {
  for (let x = 0; x <= x1 - x0; x++) {
    if (x % (dashFill + dashSkip) < dashFill) {
      const color = ranges.some(([from, to]) => from <= x && x <= to)
        ? CHOSEN_CHART_HORIZONTAL_WHITE_BACKGROUND_GRID_COLOR
        : CHOSEN_CHART_HORIZONTAL_GRAY_BACKGROUND_GRID_COLOR
      g.lineStyle(2, color, ALPHA, 0)
      g.drawLine(x0 + x, y, x0 + x + 1, y)
    }
  }
}

const drawVerticalDashLine = (g: PIXI.Graphics, x: number, y0: number, y1: number, verticalDashFill: number, verticalDashSkip: number): void => {
  g.drawVerticalLine(x, y0, y0 + verticalDashFill / 2)
  for (let y = y0 + verticalDashFill / 2 + verticalDashSkip; y <= y1; y += verticalDashFill + verticalDashSkip)
    g.drawVerticalLine(x, y, Math.min(y + verticalDashFill, y1))
}

const drawChartsWithBackground = (
  gChartBackgroundLocal: PIXI.Graphics,
  gsLocal: PIXI.Graphics[],
  dailyChartModel: DailyChartModel,
  adjoiningFullCharts: NavigationChartWithRange[],
  chosenHalfHourIndex: number,
  height: number,
  chartWidth: number,
  visibles: [boolean, boolean, boolean, boolean],
  isDemo: boolean,
  isActualDay: boolean,
  timeDemoSeconds: number,
  nowTimeMilliseconds: number,
): void => {
  const totalRange = dailyChartModel.adjoiningFullChartsStartEndTimeRange(chosenHalfHourIndex)
  if (totalRange == null) {
    // Background solid filling
    gChartBackgroundLocal.with(CHOSEN_EMPTY_BACKGROUND_COLOR, 0, g =>
      g.drawRect(0, 0, chartWidth, height), ALPHA, 0)

    // Horizontal lines
    gChartBackgroundLocal.with(CHOSEN_CHART_BACKGROUND_GRID_COLOR, HORIZONTAL_DASH_WIDTH, g =>
      gridIndexes.forEach(i =>
        drawHorizontalDashLine(
          g,
          0,
          chartWidth,
          height - HORIZONTAL_DASH_WIDTH / 2 - 17 * i,
          HORIZONTAL_DASH_FILL,
          HORIZONTAL_DASH_SKIP,
          [])))
    return
  }
  const isLastChart = dailyChartModel.isLastChartIndex(chosenHalfHourIndex)
  const isActualChart = isLastChart && (isDemo || isActualDay)
  const startTime = totalRange.from
  const endTime = isActualChart && nowTimeMilliseconds - totalRange.to < 30 * 60 * 1000
    ? Math.max(totalRange.to, nowTimeMilliseconds + 8 * 1000)
    : totalRange.to
  const timeWidth = endTime - startTime
  const xCoefficient = chartWidth / timeWidth
  const yCoefficient = height / _.max(gridIndexes)
  adjoiningFullCharts.forEach(mw => {
    // Draw backgrounds
    const from = Math.round((mw.range.time.from - startTime) * xCoefficient)
    const to = Math.round((mw.range.time.to - startTime) * xCoefficient)

    // Background solid filling
    gChartBackgroundLocal.with(CHOSEN_CHART_BACKGROUND_COLOR, 0, g =>
      g.drawRect(from, 0, to - from, height), ALPHA, 0)

    // Left and right vertical lines
    gChartBackgroundLocal.with(CHOSEN_CHART_BACKGROUND_GRID_COLOR, VERTICAL_DASH_WIDTH, g => {
      drawVerticalDashLine(g, from + 1 /* todo why? */, 0, height, VERTICAL_DASH_FILL, VERTICAL_DASH_SKIP)
      drawVerticalDashLine(g, to, 0, height, VERTICAL_DASH_FILL, VERTICAL_DASH_SKIP)
    }, ALPHA, 0)
  })

  // Horizontal lines
  const ranges = adjoiningFullCharts.map(mw => [(mw.range.time.from - startTime) * xCoefficient, (mw.range.time.to - startTime) * xCoefficient] as [number, number])
  gChartBackgroundLocal.with(CHOSEN_CHART_BACKGROUND_GRID_COLOR, HORIZONTAL_DASH_WIDTH, g =>
    gridIndexes.forEach(i =>
      drawHorizontalDashLine(
        g,
        0,
        (endTime - startTime) * xCoefficient,
        height - HORIZONTAL_DASH_WIDTH / 2 - 17 * i,
        HORIZONTAL_DASH_FILL,
        HORIZONTAL_DASH_SKIP,
        ranges)))

  // Charts
  COLOR_NUMBERS.forEach((color, i) =>
    gsLocal[i].lineStyle(CHART_LINE_WIDTH, color, ALPHA))
  adjoiningFullCharts.forEach(mw =>
    [mw.chart.concentration, mw.chart.cognitiveLoad, mw.chart.flow, mw.chart.stress].forEach(([first, ...rest], i) =>
      rest.reduce((previous, current) => {
        const xByDateFrom = (time(previous.time) - startTime) * xCoefficient
        const xByDateTo = (time(current.time) - startTime) * xCoefficient
        const y0 = height - (_.first(previous.sss).trigger - 1) * yCoefficient
        const y1 = height - (_.first(current.sss).trigger - 1) * yCoefficient
        if (visibles[i])
          gsLocal[i].drawLine(xByDateFrom, y0, xByDateTo, y1)
        return current
      }, first)))

  // Vertical red line
  if (isActualChart) {
    const x = Math.round((nowTimeMilliseconds - startTime) * xCoefficient)
    gChartBackgroundLocal.with(0xec5d3e, 2, g =>
      g.drawLine(x, height, x, 0), ALPHA, 0)
  }

  // Dots
  if (!isActualChart)
    return
  const lastChart = _.last(adjoiningFullCharts).chart;
  [_.last(lastChart.concentration), _.last(lastChart.cognitiveLoad), _.last(lastChart.flow), _.last(lastChart.stress)].forEach((p, i) => {
    const x = (time(p.time) - startTime) * xCoefficient
    const y = height - (_.first(p.sss).trigger - 1) * yCoefficient
    if (visibles[i]) {
      gsLocal[i].with(COLOR_NUMBERS[i], 2, g =>
        g.drawCircle(x, y, 5))
    }
  })
}

const ChosenChartCanvas = PixiComponent<ChosenChartCanvasProps, PIXI.Graphics>('ChosenChartCanvas',
  {
    create: () => new PIXI.Graphics(),
    applyProps: (gMain, _x, {
      chartWidth,
      height,
      visibles,
      chosenHalfHourIndex,
      dailyChartModel,
      isDemo,
      isActualDay,
      timeDemoSeconds,
      nowTimeMilliseconds,
    }) => {
      gs.map(g => g.clear())
      gChartBackground.clear()
      gBackground.clear()
      gMain.clear()

      gBackground.beginFill(CHOSEN_EMPTY_BACKGROUND_COLOR, ALPHA)
      gBackground.drawRect(0, 0, chartWidth, height)
      gBackground.endFill()
      gMain.beginFill(CHOSEN_CHART_BACKGROUND_COLOR, ALPHA)
      drawChartsWithBackground(
        gChartBackground,
        gs,
        dailyChartModel,
        dailyChartModel.adjoiningFullChartsByHalfHourIndex[chosenHalfHourIndex],
        chosenHalfHourIndex,
        height,
        chartWidth,
        visibles,
        isDemo,
        isActualDay,
        timeDemoSeconds,
        nowTimeMilliseconds)
      gMain.addChildAt(gBackground, 0)
      gMain.addChildAt(gChartBackground, 1)
      gs.forEach((g, i) =>
        gMain.addChildAt(g, i + 2))
      gMain.endFill()
    },
  },
)

interface IChosenChartProps {
  width: number
  height: number
  visibles: [boolean, boolean, boolean, boolean]
  chosenHalfHourIndex: number
  onChosenScroll: (scrollX: number, deltaX: number) => void
  scrollXLocal: number
  deltaX: number
  dailyChartModel: DailyChartModel
  isMobile: boolean
  isDemo: boolean
  isActualDay: boolean
  timeDemoSeconds: number
  nowTimeMilliseconds: number
}

interface IChosenChartState {
  wasSwiping: boolean
  isSwiping: boolean
}

const textStyle: React.CSSProperties = {
  // right: 8,
  fontFamily: 'Inter',
  fontWeight: 600,
  color: '#606872',
}

class ChosenChart extends React.Component<IChosenChartProps, IChosenChartState> {
  constructor(props) {
    super(props)

    this.state = {
      wasSwiping: false,
      isSwiping: false,
    }
  }

  public onSwipingHandler = (e: EventData): void => {
    this.props.onChosenScroll(this.props.scrollXLocal, e.deltaX)
    this.setState(prevState => ({...prevState, isSwiping: true}))
  }

  public onSwipedHandler = (_e: EventData): void => {
    this.props.onChosenScroll(this.props.scrollXLocal + this.props.deltaX, 0)
    this.setState(prevState => ({
      ...prevState,
      wasSwiping: true,
      isSwiping: false,
    }))
  }

  public render = (): React.ReactNode => {
    const {width, height, visibles, chosenHalfHourIndex, dailyChartModel, isMobile, scrollXLocal, deltaX, isDemo, isActualDay, timeDemoSeconds, nowTimeMilliseconds} = this.props
    const totalRange = dailyChartModel.adjoiningFullChartsStartEndTimeRange(chosenHalfHourIndex)
    const isLastChart = dailyChartModel.isLastChartIndex(chosenHalfHourIndex)
    const isActualChart = isLastChart && (isDemo || isActualDay)
    const endTime = totalRange && (isActualChart && nowTimeMilliseconds - totalRange.to < 30 * 60 * 1000
      ? Math.max(totalRange.to, nowTimeMilliseconds + 8 * 1000)
      : totalRange.to)

    const timeWidth = totalRange && endTime - totalRange.from
    const fiveTimeCount = totalRange && timeWidth / FIVE_MINUTES_TIME - 1
    const viewWidth = width - RIGHT_PANEL_WIDTH
    const chartWidth = timeWidth < HALF_HOUR_TIME
      ? viewWidth
      : viewWidth * timeWidth / HALF_HOUR_TIME
    const isLessThanHalfHour = timeWidth < HALF_HOUR_TIME
    const fiveTimeWidth = viewWidth / ((isLessThanHalfHour ? timeWidth : HALF_HOUR_TIME) / FIVE_MINUTES_TIME)
    return (
      <div>
        <div style={{position: 'relative', float: 'right', right: 0}}>
          {gridIndexes.map(i =>
            <span key={i} style={{
              ...textStyle,
              position: 'absolute',
              float: 'right',
              fontSize: 11,
              top: height - 9 - i * 17,
              right: 10,
            }}>{i + 1}</span>)}
        </div>
        <div style={{width: viewWidth}}>
          <Swipeable
            onSwiping={this.onSwipingHandler}
            onSwiped={this.onSwipedHandler}
            delta={30}>
            <Scrollbar
              style={{height: 190}}
              trackXProps={{style: {height: 6, width: PANEL_WIDTH - 2, marginLeft: -10, background: 'none'}}}
              // scrollerProps={{style: {width: viewWidth + 77/*todo wtf*/}}}
              scrollLeft={isDemo || isActualDay ? chartWidth : scrollXLocal + deltaX}
              noScrollY={true}
              noScrollX={isMobile || isLessThanHalfHour}>
              <Stage width={chartWidth} height={height} options={{backgroundColor: CHOSEN_EMPTY_BACKGROUND_COLOR, antialias: true}}>
                <ChosenChartCanvas
                  chartWidth={chartWidth}
                  height={height}
                  visibles={visibles}
                  chosenHalfHourIndex={chosenHalfHourIndex}
                  dailyChartModel={dailyChartModel}
                  isDemo={isDemo}
                  isActualDay={isActualDay}
                  timeDemoSeconds={timeDemoSeconds}
                  nowTimeMilliseconds={nowTimeMilliseconds}/>
              </Stage>
              <div style={{width, marginTop: 7, marginLeft: 38}}>
                {_.range(fiveTimeCount).map(i => {
                  const startDate = new Date(totalRange.from)
                  const fiveMinutesDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes() + 5 * (i + 1))
                  const hours = fiveMinutesDate.getHours().toString().padStart(2, '0')
                  const minutes = fiveMinutesDate.getMinutes().toString().padStart(2, '0')
                  return <span key={i} style={{
                    ...textStyle,
                    fontSize: 10,
                    position: 'absolute',
                    left: (i + 1) * fiveTimeWidth - 14,
                  }}>{`${hours}:${minutes}`}</span>
                })}
              </div>
            </Scrollbar>
          </Swipeable>
        </div>
      </div>)
  }
}

export default ChosenChart