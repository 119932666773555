import {Action, ActionCreator} from 'redux'
import {ThunkAction} from 'redux-thunk'
import {createRequestState, handleRequest, IRequestAction, IRequestState} from '../common/request-state'
import {ApplicationState} from './index'
import {Client, DailyMentalWaves, EventDataPoint} from '../client/client.generated'

const authClient = new Client()

export const SET_IDENTITY = 'SET_IDENTITY'
export const SET_LOGIN_REQUEST = 'SET_LOGIN_REQUEST'
export const SET_LOGOUT_REQUEST = 'SET_LOGOUT_REQUEST'
export const SET_FIND = 'SET_FIND'
export const SET_FIND_REQUEST = 'SET_FIND_REQUEST'

export interface AuthState {
  identity: any | null
  identityLoaded: boolean
  loginRequest: IRequestState<any>
  findRequest: IRequestState<EventDataPoint[]>
}

export interface IIdentityAction extends Action {
  identity: any | null
}

export interface IFindAction extends Action {
  dailyMentalWaves: DailyMentalWaves
}

const defaultState: AuthState = {
  identity: null,
  identityLoaded: false,
  loginRequest: createRequestState(),
  findRequest: createRequestState(),
}

export const reducer = (state: AuthState = defaultState, action: Action): AuthState => {
  const identityAction = action as IIdentityAction
  switch (action.type) {
    case SET_IDENTITY:
      return {
        ...state,
        identityLoaded: true,
        identity: identityAction.identity,
      }
    case SET_LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: (action as IRequestAction<any>).request,
      }
    case SET_FIND_REQUEST:
      return {
        ...state,
        findRequest: (action as IRequestAction<EventDataPoint[]>).request,
      }
    default:
      return state
  }
}

export const setIdentity = (identity: any | null): IIdentityAction => ({
  identity,
  type: SET_IDENTITY,
})

export const login: ActionCreator<ThunkAction<Promise<IIdentityAction>, AuthState, void, IIdentityAction>> = () =>
  async dispatch =>
    dispatch(setIdentity((await handleRequest(authClient.login(), dispatch, SET_LOGIN_REQUEST)).data))

export const logout: ActionCreator<ThunkAction<Promise<IIdentityAction>, ApplicationState, void, IIdentityAction>> = () =>
  async dispatch => {
    await handleRequest(authClient.logout(), dispatch, SET_LOGOUT_REQUEST)
    return dispatch(setIdentity(null))
  }

export const setCurrentUser: ActionCreator<ThunkAction<Promise<IIdentityAction>, ApplicationState, void, IIdentityAction>> = () =>
  async (dispatch, getState) => {
    const state = getState()

    if (!state.auth.identityLoaded) {
      try {
        const identity = await authClient.getCurrentUser()
        return dispatch(setIdentity(identity))
      } catch (e) {
        return dispatch(setIdentity(null))
      }
    }
    return null
  }