import * as PIXI from 'pixi.js'

declare module 'pixi.js' {
  interface Graphics {
    with(color: number, width: number, graphicsCallback: (g: PIXI.Graphics) => void, alpha?: number, alignment?: number)

    drawLine(x0: number, y0: number, x1: number, y1: number)

    drawHorizontalLine(x0: number, x1: number, y: number)

    drawVerticalLine(x: number, y0: number, y1: number)
  }
}

// eslint-disable-next-line no-undefined
PIXI.Graphics.prototype.with = function (color: number, width: number, graphicsCallback: (g: PIXI.Graphics) => void, alpha = 1, alignment: number = undefined) {
  this.beginFill(color, alpha)
  this.lineStyle(width, color, alpha, alignment)
  graphicsCallback(this)
  this.endFill()
}

PIXI.Graphics.prototype.drawLine = function (x0: number, y0: number, x1: number, y1: number) {
  this.moveTo(x0, y0)
  this.lineTo(x1, y1)
}

PIXI.Graphics.prototype.drawHorizontalLine = function (x0: number, x1: number, y: number) {
  this.moveTo(x0, y)
  this.lineTo(x1, y)
}

PIXI.Graphics.prototype.drawVerticalLine = function (x: number, y0: number, y1: number) {
  this.moveTo(x, y0)
  this.lineTo(x, y1)
}