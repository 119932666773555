/* eslint-disable */
import * as React from 'react'
import {AuthState, logout, setCurrentUser} from '../store/Auth'
import {AppState} from '../store/App'
import {ApplicationState} from '../store'
import {connect} from 'react-redux'
import Preloader from './controls/Preloader'
import {Client, DailyMentalWaves, DateTimeRange, MentalWaves, Trigger, TypeOfTrigger} from '../client/client.generated'
import i18next from 'i18next'
import * as _ from 'lodash'
import {PANEL_WIDTH} from './controls/daily-chart/common'
import * as Common from '../common/common'
import {DailyChartModel} from './controls/daily-chart/DailyChartModel'
import '../style.css'
import DailyChart from './controls/daily-chart/DailyChart'
import InstallAppBadge from './InstallAppBadge'
import * as classNames from 'classnames'
import {useMediaQuery} from '@material-ui/core'
import json2mq from 'json2mq'
import * as Amplitude from '@amplitude/node'

// const BG = '#fafafa'
const MIN_WIDTH = 377
const MAX_WIDTH = PANEL_WIDTH
const APP_BADGE_WIDTH = 260
const APP_BADGE_MARGIN = 20

// const amplitudeClient = require('amplitude-js');
// amplitudeClient.getInstance().init("d31eb203150a0765ae57d72a5fab796d");

interface ILayoutProps extends AuthState, AppState {
  mediaQuery: boolean

  setCurrentUser(): void

  logout(): void
}

const minMaxWidth = (width: number): number =>
  _.clamp(width, MIN_WIDTH, MAX_WIDTH)

interface ILayoutState {
  profileMenuAnchorEl: HTMLElement
  width: number
  badgeVisible: boolean
  dailyMentalWaves: DailyMentalWaves
  dailyChartModel: DailyChartModel
  nowTimeMilliseconds: number
  onLine: boolean
}

// const detectMobile = (): boolean =>
//   useMediaQuery(json2mq({maxWidth: MAX_WIDTH + (APP_BADGE_WIDTH + APP_BADGE_MARGIN * 2) * 2}))

const withMediaQuery = (args: any) => Component => props =>
  <Component mediaQuery={useMediaQuery(args)} {...props} />

class Layout extends React.Component<ILayoutProps, ILayoutState> {
  private timerId: NodeJS.Timeout
  private readonly client = new Client()

  constructor(props: any) {
    super(props)

    this.state = {
      profileMenuAnchorEl: null,
      width: minMaxWidth(window.innerWidth),
      badgeVisible: true,
      dailyMentalWaves: null,
      dailyChartModel: null,
      nowTimeMilliseconds: null,
      onLine: window.navigator.onLine,
    }
  }

  public setLanguage = async (language: string): Promise<void> => {
    await i18next.init({
      lng: language,
      resources: require(`./${language}.json`),
    }).then(() => this.setState({}))

    // this.props.actions.changeLanguage(i18next) todo?
  }

  public newDailyChartModel = (dailyMentalWaves: DailyMentalWaves): DailyChartModel =>
    new DailyChartModel(dailyMentalWaves.mentalWavesCollection.filter((mw: MentalWaves): any =>
      mw.concentration.some(x => mw.range.from <= x.time && x.time <= mw.range.to) &&
      mw.cognitiveLoad.some(x => mw.range.from <= x.time && x.time <= mw.range.to) &&
      mw.flow.some(x => mw.range.from <= x.time && x.time <= mw.range.to) &&
      mw.stress.some(x => mw.range.from <= x.time && x.time <= mw.range.to)));

  public auth = async (): Promise<void> => {
    await fetch(`/api/User/${this.match('userId')}/${this.match('timestamp') || 0}/${this.match('timestampDemo') || 0}`, {method: 'POST'})
  }

  public componentDidMount = async (): Promise<void> => {
    await this.auth()
    const amplitudeClient = Amplitude.init('d31eb203150a0765ae57d72a5fab796d')
    window.addEventListener('online', () => this.setState(prevState => ({...prevState, onLine: window.navigator.onLine})))
    window.addEventListener('offline', () => this.setState(prevState => ({...prevState, onLine: window.navigator.onLine})))
    window.addEventListener('resize', () => this.setState(prevState => ({...prevState, width: minMaxWidth(window.innerWidth)})))
    window.addEventListener('beforeunload', _e =>
      amplitudeClient.logEvent({
        event_type: 'Web Session end',
        user_id: this.match('userId'),
        language: navigator.language,
        platform: 'web',
        // version_name: '',
      }))
    amplitudeClient.logEvent({
      event_type: 'Web Session start',
      user_id: this.match('userId'),
      language: navigator.language,
      platform: 'web',
      // version_name: '',
    })
    // document.body.style.minHeight = '100%'
    this.props.setCurrentUser()
    await this.setLanguage(Common.languageCode())

    const startTime = new Date().getTime()
    const dailyMentalWaves = await this.client.find(null, null)
    const nowTimeMilliseconds = dailyMentalWaves.isDemo
      ? dailyMentalWaves.timeDemoSeconds * 1000
      : dailyMentalWaves.isActualDay
        ? new Date().getTime()
        : null
    this.setState(prevState => ({
      ...prevState,
      dailyMentalWaves,
      dailyChartModel: this.newDailyChartModel(dailyMentalWaves),
      nowTimeMilliseconds,
    }))
    console.log('init', (new Date().getTime() - startTime) / 1000)

    if (dailyMentalWaves.isActualDay)
      this.timerId = setInterval((): any => this.tick(), (dailyMentalWaves.isDemo ? 5 : 60) * 1000)
  }

  public componentWillUnmount = (): void =>
    clearInterval(this.timerId)

  public merge = (additional: DailyMentalWaves): DailyMentalWaves => {
    const dailyMentalWaves = _.clone(this.state.dailyMentalWaves)
    const firstAdditionalMentalWaves = _.first(additional.mentalWavesCollection)
    // console.log('ids', additional.mentalWavesCollection.map(x => x.concentrationEventId))
    if (firstAdditionalMentalWaves != null) {
      const lastMentalWaves = _.last(dailyMentalWaves.mentalWavesCollection)
      let concentration = _.clone(lastMentalWaves.concentration)
      let cognitiveLoad = _.clone(lastMentalWaves.cognitiveLoad)
      let flow = _.clone(lastMentalWaves.flow)
      let stress = _.clone(lastMentalWaves.stress)
      if (lastMentalWaves.concentrationEventId === firstAdditionalMentalWaves.concentrationEventId) {
        concentration = concentration.concat(firstAdditionalMentalWaves.concentration)
        cognitiveLoad = cognitiveLoad.concat(firstAdditionalMentalWaves.cognitiveLoad)
        flow = flow.concat(firstAdditionalMentalWaves.flow)
        stress = stress.concat(firstAdditionalMentalWaves.stress)
        const newMentalWaves = {
          concentrationEventId: lastMentalWaves.concentrationEventId,
          range: {from: lastMentalWaves.range.from, to: firstAdditionalMentalWaves.range.to} as DateTimeRange,
          concentration,
          cognitiveLoad,
          flow,
          stress,
        } as MentalWaves
        dailyMentalWaves.mentalWavesCollection.splice(-1, 1, newMentalWaves)
      } else
        dailyMentalWaves.mentalWavesCollection.push(firstAdditionalMentalWaves)
    }
    dailyMentalWaves.triggers.splice(0, dailyMentalWaves.triggers.length, ...dailyMentalWaves.triggers.concat(additional.triggers))
    return dailyMentalWaves
  }

  public tick = async (): Promise<void> => {
    // const lastPointTimeFrom = Common.time(_.last(_.last(this.state.dailyMentalWaves.mentalWavesCollection).concentration).time)
    const nowTimeMilliseconds = this.state.nowTimeMilliseconds + 60 * 1000
    const startTime = new Date().getTime()
    /*
    const dailyMentalWavesAdditional: DailyMentalWaves = await this.client.find(lastPointTimeFrom + 1000 /!* ms *!/, nowTimeMilliseconds)
    // console.log('tick', dailyMentalWavesAdditional.mentalWavesCollection.length, lastPointTimeFrom, new Date(lastPointTimeFrom), nowTimeMilliseconds, new Date(nowTimeMilliseconds))
    const dailyMentalWaves = this.merge(dailyMentalWavesAdditional)
    */
    const nowDate = new Date(this.state.nowTimeMilliseconds)
    // const startToday = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate())
    const startTodayUTCTime = Date.UTC(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate())
    const dailyMentalWaves: DailyMentalWaves = await this.client.find(startTodayUTCTime, nowTimeMilliseconds)

    if (new Date(nowTimeMilliseconds).getDay() !== new Date(this.state.nowTimeMilliseconds).getDay())
      clearInterval(this.timerId)

    this.setState(prevState => ({
      ...prevState,
      dailyMentalWaves,
      dailyChartModel: this.newDailyChartModel(dailyMentalWaves),
      nowTimeMilliseconds,
    }))
    console.log('merge setState', (new Date().getTime() - startTime) / 1000, new Date(nowTimeMilliseconds))
    // this.setState()
  }

  public triggerMessage = (trigger: Trigger): [string, string] => {
    let color: string
    let message: string
    // const date = new Date(trigger.dateTime)
    switch (trigger.typeOfTrigger) {
      case TypeOfTrigger._0:
        color = '#7ab1ff'
        message = i18next.t('trigger_high_focus')
        break
      case TypeOfTrigger._1:
        color = '#ff7272'
        message = i18next.t('trigger_high_cognitive_load')
        break
      case TypeOfTrigger._2:
        color = '#fe9e76'
        message = i18next.t('trigger_high_emotional_weight')
        break
      case TypeOfTrigger._3:
        color = '#45d09e'
        message = i18next.t('trigger_high_flow')
        break
      case TypeOfTrigger._4:
        color = '#7ab1ff'
        message = i18next.t('trigger_low_focus')
        break
      case TypeOfTrigger._5:
        color = '#ff7272'
        message = i18next.t('trigger_low_cognitive_load')
        break
      case TypeOfTrigger._6:
        color = '#fe9e76'
        message = i18next.t('trigger_low_emotional_weight')
        break
      case TypeOfTrigger._7:
        color = '#45d09e'
        message = i18next.t('trigger_low_flow')
        break
      case TypeOfTrigger._8:
        color = '#60c1cf'
        message = i18next.t('trigger_great')
        break
      default:
        color = '#9966cc'
        message = i18next.t('trigger_poor')
    }
    return [color, i18next.t(message)]
    // (<Typography key={`span-${i}`}><Chip label={`${date.getHours()}:${date.getMinutes()}`} className={classes.journalMessage} style={{backgroundColor: color}}/>{i18next.t(message)}</Typography>)
  }

  public hide = (): void =>
    this.setState({badgeVisible: false})

  public match = (paramName: string): any =>
    (this.props as any).match.params[paramName]

  public shouldComponentUpdate = (_nextProps: Readonly<ILayoutProps>, _nextState: Readonly<ILayoutState>, _nextContext: any): boolean =>
    true

  public render(): React.ReactNode {
    const {width, dailyMentalWaves, dailyChartModel, nowTimeMilliseconds, badgeVisible, onLine} = this.state
    const {identityLoaded, mediaQuery} = this.props

    if (!identityLoaded || dailyChartModel == null)
      return <Preloader/>

    const currentDate = new Date(dailyMentalWaves.day)

    /* const dataMinutesX = []
    const dataMinutesY = []
    const divider = 10
    for (let i = 0; i < 24 * 60 / divider; ++i) {
      dataMinutesX.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, i * divider, 0))
      dataMinutesY.push(1)
    } */
    // const mentalWavesCollection = dailyMentalWaves.mentalWavesCollection
    return (
      <section className='wrap'>
        <InstallAppBadge
          mobileVisible={badgeVisible}
          isMobile={mediaQuery}
          hide={this.hide}/>
        <DailyChart
          width={width - 2}
          dailyChartModel={dailyChartModel}
          date={currentDate}
          isDemo={dailyMentalWaves.isDemo}
          isActualDay={dailyMentalWaves.isActualDay}
          timeDemoSeconds={dailyMentalWaves.timeDemoSeconds}
          nowTimeMilliseconds={nowTimeMilliseconds}
          onLine={onLine}/>
        <div className='journal'>
          <div className={classNames('journal-top', 'flex')}>
            <h2>{i18next.t('journal')}</h2>
            <a href='https://m.me/100663175007552' target='_blank' rel='noopener noreferrer'>
              <button className='write-tech-support'>{i18next.t('write_to_tech_support')}</button>
            </a>
          </div>
          <div className='journal-table'>
            {dailyMentalWaves.triggers.map((p, i) => {
              const pair = this.triggerMessage(p)
              const d = new Date(p.dateTime)
              return <div key={i} className={classNames('journal-table-string', 'flex')}>
                <div
                  className='journal-time'
                  style={{backgroundColor: _.first(pair)}}>{`${d.getHours()}:${d.getMinutes().toString().padStart(2, '0')}`}</div>
                <div className='journal-text'>{i18next.t(_.last(pair))}</div>
              </div>
            })}
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state: ApplicationState): AuthState & AppState =>
  ({...state.auth, ...state.app})

export default connect(mapStateToProps, {setCurrentUser, logout})(
  withMediaQuery(json2mq({maxWidth: MAX_WIDTH + (APP_BADGE_WIDTH + APP_BADGE_MARGIN * 2) * 2}))(Layout))