import * as H from 'history'
import {applyMiddleware, combineReducers, compose, createStore, Store} from 'redux'
import thunk from 'redux-thunk'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {ApplicationState, reducers} from './'

export default (historyObject: H.History, initialState?: ApplicationState): Store => {
  const middleware = [
    thunk,
    routerMiddleware(historyObject),
  ]

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(historyObject),
  })

  const enhancers = []
  const windowIfDefined = typeof window === 'undefined'
    ? null
    : window as any
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__)
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__())

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  )
}