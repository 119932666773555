/* eslint-disable */
import {Action, Dispatch} from 'redux'
import {ApiException} from '../client/client.generated'
import {setIdentity} from '../store/Auth'

export interface IRequestAction<T> extends Action {
  readonly request: IRequestState<T>
}

export type IRequestErrors = Readonly<{
  [name: string]: string[]
}>

export type IRequestState<T = {}> = Readonly<{
  data: T
  loading: boolean
  succeeded: boolean
  failed: boolean
  error?: string
  errors?: IRequestErrors
}>

export const createRequestState = <T = {}>(partial: Partial<IRequestState<T>> = {}): IRequestState<T> => ({
  data: null,
  loading: false,
  succeeded: false,
  failed: false,
  error: null,
  errors: null,
  ...partial,
})

export const handleRequest = async <T>(promise: Promise<T>, dispatch: Dispatch<IRequestAction<T>>, type: string): Promise<IRequestState<T>> => {
  let request = createRequestState<T>({loading: true})
  dispatch({type, request})

  try {
    const data = await promise
    request = {...request, loading: false, succeeded: true, data}
  } catch (e) {
    const ex = e as ApiException
    const dispatchAction = dispatch as Dispatch<Action>
    let error: string = null
    let errors: IRequestErrors = null
    if (ex.status === 500)
      error = 'An internal server error occurred'
    else {
      if (ex.status === 401)
        dispatchAction(setIdentity(null))
      const response = ex.response
        ? JSON.parse(ex.response)
        : ''
      if (typeof response === 'string')
        error = response
      else
        errors = response
    }
    request = {...request, loading: false, failed: true, error, errors}
  }
  dispatch({type, request})
  return request
}