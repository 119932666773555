/* eslint-disable */
import React from 'react'
import {ApplicationState} from '../../../store'
import {connect} from 'react-redux'
import {DailyChartModel} from './DailyChartModel'
import * as _ from 'lodash'
import '../../../style.css'
import i18next from 'i18next'
import * as Common from '../../../common/common'
import * as classNames from 'classnames'
import IndicatorsHelpDialog from './IndicatorsHelpDialog'
import NavigationChart from './NavigationChart'
import {RIGHT_PANEL_WIDTH} from './common'
import ChosenChart from './ChosenChart'

const DASH_WIDTH = 2
const CHOSEN_CHART_ONE_SECTION = 17
const CHOSEN_CHART = CHOSEN_CHART_ONE_SECTION * 9 + DASH_WIDTH

const NAVIGATION_CHART = 72 + 1

// const styles = (_theme: Theme): StyleRules => createStyles({})

interface IDailyChartProps {
  width: number
  dailyChartModel: DailyChartModel
  date: Date
  isDemo: boolean
  isActualDay: boolean
  timeDemoSeconds: number
  nowTimeMilliseconds: number
  onLine: boolean
}

interface IDailyChartState {
  isHelpDialogOpened: boolean
  visibles: [boolean, boolean, boolean, boolean]
  chosenHalfHourIndex: number
  chosenScrollX: number
  chosenDeltaX: number
}

const showDay = (date: Date): string => { // todo to init
  const today = new Date()
  const todayTime = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()
  const yesterdayTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).getTime()
  const dateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
  return dateTime === todayTime
    ? i18next.t('today')
    : dateTime === yesterdayTime
      ? i18next.t('yesterday')
      : `${date.toLocaleString(Common.isRussian() ? 'ru' : 'en-GB', {
        month: 'long',
        day: 'numeric',
      })}${today.getFullYear() === date.getFullYear() ? '' : ` ${date.getFullYear()}`}`
}

class DailyChart extends React.Component<IDailyChartProps, IDailyChartState> {
  constructor(props: any) {
    super(props)

    this.state = {
      isHelpDialogOpened: false,
      visibles: [true, true, true, true],
      chosenHalfHourIndex: 0,
      chosenScrollX: 0, // this.props.dailyChartModel.chosenCenterByIndex(0, this.props.width - RIGHT_PANEL_WIDTH),
      chosenDeltaX: 0,
    }
  }

  public onHelpDialogOpen = (): void =>
    this.setState({isHelpDialogOpened: true})

  public onHelpDialogClose = (): void =>
    this.setState({isHelpDialogOpened: false})

  public onChoseHalfHourHandler = (dailyChartModel: DailyChartModel, chosenViewWidth: number) =>
    (i: number): void => this.setState(prevState => ({
      ...prevState,
      chosenHalfHourIndex: i,
      chosenScrollX: dailyChartModel.chosenCenterByIndex(i, chosenViewWidth),
      chosenDeltaX: 0,
    }))

  public onClickHandler = (curveNumber: number): any =>
    (_e: any): void => this.switchCurve(curveNumber)

  public onChosenScrollHandler = (chosenScrollX: number, chosenDeltaX: number): void =>
    this.setState(prevState => ({...prevState, chosenScrollX, chosenDeltaX}))

  public switchCurve = (curveNumber: number): void =>
    this.setState(prevState => { // todo splice
      const visibles = _.clone(prevState.visibles)
      visibles[curveNumber] = !visibles[curveNumber]
      return {...prevState, visibles}
    })

  public render(): React.ReactNode {
    const isMobile = Common.isMobile()
    const {width, date, dailyChartModel, isDemo, isActualDay, timeDemoSeconds, nowTimeMilliseconds, onLine} = this.props
    const chosenViewWidth = width - RIGHT_PANEL_WIDTH
    const {isHelpDialogOpened, visibles, chosenHalfHourIndex, chosenDeltaX, chosenScrollX} = this.state
    const flexFilterItem = classNames('flex', 'filter-item')
    return <div>
      <div className='chart'>
        <div className='chart-date'>{showDay(date)}</div>
        <NavigationChart
          width={width}
          height={NAVIGATION_CHART}
          visibles={visibles}
          onChoseHalfHour={this.onChoseHalfHourHandler(dailyChartModel, chosenViewWidth)}
          date={date}
          dailyChartModel={dailyChartModel}
          isMobile={isMobile}/>
      </div>
      {!onLine && <div className='offline'>
        <div className='flex' style={{padding: '16px 10px 6px 0'}}>
          <img className='journal-time' src='/Offline.svg' alt='Offline' style={{padding: 0}}/>
          <div className='journal-text'>
            <span style={{fontWeight: 500}}>{i18next.t('there_is_no_connection_to_the_server')} </span>
            {i18next.t('there_is_no_connection_to_the_server_description')}
            <a href='https://m.me/100663175007552' target='_blank' rel='noopener noreferrer' className='write-tech-support-link'>
              <button className='write-tech-support' style={{display: 'block', marginTop: 20}}>{i18next.t('write_to_tech_support')}</button>
            </a>
          </div>
        </div>
      </div>}
      <img alt='This is hook for load Offline.svg before true offline case' src='Offline.svg' style={{display: 'none'}}/>
      <div className='chart' style={{paddingTop: 14}}>
        <ChosenChart
          width={width}
          height={CHOSEN_CHART}
          visibles={visibles}
          chosenHalfHourIndex={chosenHalfHourIndex}
          onChosenScroll={this.onChosenScrollHandler}
          scrollXLocal={chosenScrollX}
          deltaX={chosenDeltaX}
          dailyChartModel={dailyChartModel}
          isMobile={isMobile}
          isDemo={isDemo}
          isActualDay={isActualDay}
          timeDemoSeconds={timeDemoSeconds}
          nowTimeMilliseconds={nowTimeMilliseconds}
        />
        <div className={classNames('chart-filter', 'flex')}>
          <div className={classNames(flexFilterItem, {'filter-item-disable': !visibles[0]})} onClick={this.onClickHandler(0)}>
            <div className={classNames('circle', 'blue')}/>
            {i18next.t('concentration')}
          </div>
          <div className={classNames(flexFilterItem, {'filter-item-disable': !visibles[1]})} onClick={this.onClickHandler(1)}>
            <div className={classNames('circle', 'red')}/>
            {i18next.t('cognitive_load')}
          </div>
          <div className={classNames(flexFilterItem, {'filter-item-disable': !visibles[2]})} onClick={this.onClickHandler(2)}>
            <div className={classNames('circle', 'green')}/>
            {i18next.t('flow')}
          </div>
          <div className={classNames(flexFilterItem, {'filter-item-disable': !visibles[3]})} onClick={this.onClickHandler(3)}>
            <div className={classNames('circle', 'orange')}/>
            {i18next.t('stress')}
          </div>
          <div className={classNames('flex', 'filter-btn', 'open-modal')} onClick={this.onHelpDialogOpen}>{i18next.t('what_are_these_indicators')}</div>
        </div>
        <IndicatorsHelpDialog open={isHelpDialogOpened} onClose={this.onHelpDialogClose}/>
      </div>
    </div>
  }
}

const mapStateToProps = (state: ApplicationState): any =>
  state

export default connect(mapStateToProps, {})(DailyChart)