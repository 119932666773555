const BLUE_NUMBER = 0x7ab1ff
const RED_NUMBER = 0xff7272
const GREEN_NUMBER = 0x45d09e
const ORANGE_NUMBER = 0xfe9e76
export const COLOR_NUMBERS = [BLUE_NUMBER, RED_NUMBER, GREEN_NUMBER, ORANGE_NUMBER]

const ANOTHER_BLUE_NUMBER = 0xbad5fd
const ANOTHER_RED_NUMBER = 0xfdb6b6
const ANOTHER_GREEN_NUMBER = 0x9fe5cc
const ANOTHER_ORANGE_NUMBER = 0xfcccb8
export const ANOTHER_COLOR_NUMBERS = [ANOTHER_BLUE_NUMBER, ANOTHER_RED_NUMBER, ANOTHER_GREEN_NUMBER, ANOTHER_ORANGE_NUMBER]

export const BLUE_STRING = '#7ab1ff'
export const RED_STRING = '#ff7272'
export const GREEN_STRING = '#45d09e'
export const ORANGE_STRING = '#fe9e76'

export const ALPHA = 1

const HOURS = 24
const FIVE = 5
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HALF_HOUR = 30
export const HALF_HOURS = HOURS * 2
export const HALF_HOUR_TIME = MINUTES_IN_HALF_HOUR * SECONDS_IN_MINUTE * 1000
export const FIVE_MINUTES_TIME = FIVE * SECONDS_IN_MINUTE * 1000

export const RIGHT_PANEL_WIDTH = 39

export const PANEL_WIDTH = 598 // todo -> props