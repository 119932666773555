/* eslint-disable */
  import React from 'react'
import {PixiComponent, Stage} from '@inlet/react-pixi'
import * as PIXI from 'pixi.js'
import '../../../common/extensions/pixi'
import Scrollbar from 'react-scrollbars-custom'
import {Swipeable} from 'react-swipeable'
import {DailyChartModel, PADDING} from './DailyChartModel'
import * as _ from 'lodash'
import {ALPHA, COLOR_NUMBERS, PANEL_WIDTH} from './common'
import {time} from '../../../common/common'

const NAVIGATION_DEFAULT_BACKGROUND_COLOR = 0xfafafa
const NAVIGATION_MOUSE_OVER_BACKGROUND_COLOR = 0xf2f2f2
const NAVIGATION_CHOSEN_BACKGROUND_COLOR = 0xefefef

interface NavigationChartCanvasProps {
  height: number
  dailyChartModel: DailyChartModel
  visibles: [boolean, boolean, boolean, boolean]
  chosenHalfHourIndex: number
  mouseHalfHourIndex: number
}

const drawLines = (graphics: PIXI.Graphics, points: number[]): void => {
  graphics.moveTo(points[0], points[1])
  for (let i = 2; i < points.length - 1; i += 2)
    graphics.lineTo(points[i], points[i + 1])
}

const getCurvePoints = (points: number[], tension: number): number[] => {
  const numberOfSegments = 1

  const res = [] // clone array
  let x, y, // our x,y coords
    t1x, t2x, t1y, t2y, // tension vectors
    c1, c2, c3, c4, // cardinal points
    st, t, i // steps based on number of segments

  // clone array so we don't change the original
  const _points = points.slice(0)

  // The algorithm require a previous and next point to the actual point array.
  // Check if we will draw closed or open curve.
  // If closed, copy end points to beginning and first points to end
  // If open, duplicate first points to befinning, end points to end
  _points.unshift(points[1]) // copy 1. point and insert at beginning
  _points.unshift(points[0])
  _points.push(points[points.length - 2]) // copy last point and append
  _points.push(points[points.length - 1])

  // ok, lets start..

  // 1. loop goes through point array
  // 2. loop goes through each segment between the 2 pts + 1e point before and after
  for (i = 2; i < _points.length - 4; i += 2) {
    for (t = 0; t <= numberOfSegments; t++) {
      // calc tension vectors
      t1x = (_points[i + 2] - _points[i - 2]) * tension
      t2x = (_points[i + 4] - _points[i]) * tension

      t1y = (_points[i + 3] - _points[i - 1]) * tension
      t2y = (_points[i + 5] - _points[i + 1]) * tension

      // calc step
      st = t / numberOfSegments

      // calc cardinals
      c1 = 2 * Math.pow(st, 3) - 3 * Math.pow(st, 2) + 1
      c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2)
      c3 = Math.pow(st, 3) - 2 * Math.pow(st, 2) + st
      c4 = Math.pow(st, 3) - Math.pow(st, 2)

      // calc x and y cords with common control vectors
      x = c1 * _points[i] + c2 * _points[i + 2] + c3 * t1x + c4 * t2x
      y = c1 * _points[i + 1] + c2 * _points[i + 3] + c3 * t1y + c4 * t2y

      // store points in array
      res.push(x)
      res.push(y)
    }
  }

  return res
}

const drawCurve = (
  ctx: PIXI.Graphics,
  ptsa: number[],
  firstPoint: [number, number],
  lastPoint: [number, number],
  tension: number,
  color: number,
  alpha: number,
): void => {
  ctx.beginFill(color, alpha)
  ctx.lineStyle(1, color)
  drawLines(ctx, firstPoint.concat(getCurvePoints(ptsa, tension)).concat(lastPoint))
  ctx.endFill()
}

const gChartBackground = new PIXI.Graphics()
const gs = _.range(COLOR_NUMBERS.length).map(_c => new PIXI.Graphics())

const drawChartsWithBackground = (
  gChartBackgroundLocal: PIXI.Graphics,
  gsLocal: PIXI.Graphics[],
  dailyChartModel: DailyChartModel,
  height: number,
  heightValues: number,
  xOffset: number,
  visibles: [boolean, boolean, boolean, boolean],
  chosenHalfHourIndex: number,
  mouseHalfHourIndex: number,
): void => {
  dailyChartModel.halfHourCharts.forEach((navigationHalfHourChartsWithRange, halfHourIndex) => {
    const isChosen = halfHourIndex === chosenHalfHourIndex
    const isMouseOver = halfHourIndex === mouseHalfHourIndex
    const halfHour = dailyChartModel.halfHours[halfHourIndex]

    if (halfHourIndex > 0) { // todo wtf previous rectangle is drawn itself before the current one
      const previousHalfHour = dailyChartModel.halfHours[halfHourIndex - 1]
      if (halfHour.pixels.from > previousHalfHour.pixels.to) {
        gChartBackgroundLocal.with(NAVIGATION_DEFAULT_BACKGROUND_COLOR, 1, g =>
          g.drawRect(previousHalfHour.pixels.to + 1, 0, halfHour.pixels.from - 1, height), ALPHA, 0)
      }
    }
    const bgColor = isMouseOver
      ? NAVIGATION_MOUSE_OVER_BACKGROUND_COLOR
      : isChosen
        ? NAVIGATION_CHOSEN_BACKGROUND_COLOR
        : NAVIGATION_DEFAULT_BACKGROUND_COLOR
    gChartBackgroundLocal.with(bgColor, 1, g =>
      g.drawRect(halfHour.pixels.from, 0, halfHour.pixels.to - 10, height), ALPHA, 0)

    /*
    const myPointsC = new Array<number[]>()
    myPointsC.push(new Array<number>())
    myPointsC.push(new Array<number>())
    myPointsC.push(new Array<number>())
    myPointsC.push(new Array<number>())
    _.range(HALF_HOUR_WIDTH).forEach(offsetX =>
      _.range(4).filter(i => visibles[i]).reverse().reduce((accumulator, channelIndex) => {
        const average = dailyChartModel.halfHourChartAverages[halfHourIndex][offsetX][channelIndex]
        // const color = isChosen
        //   ? COLOR_NUMBERS[channelIndex]
        //   : ANOTHER_COLOR_NUMBERS[channelIndex]
        // gsLocal[channelIndex].with(color, 1, g =>
        //   g.drawLine(halfHour.pixels.from + offsetX, accumulator, halfHour.pixels.from + offsetX, accumulator - average), ALPHA, 0)
        myPointsC[channelIndex].push(halfHour.pixels.from + offsetX, accumulator - average)
        return accumulator - average
      }, height))
    */
    navigationHalfHourChartsWithRange.forEach(navigationHalfHourChartWithRange => {
      const a = new Array<number[]>()
      a.push(new Array<number>())
      a.push(new Array<number>())
      a.push(new Array<number>())
      a.push(new Array<number>())
      const charts = [
        navigationHalfHourChartWithRange.chart.concentration,
        navigationHalfHourChartWithRange.chart.cognitiveLoad,
        navigationHalfHourChartWithRange.chart.flow,
        navigationHalfHourChartWithRange.chart.stress,
      ]
      _.first(charts).forEach((_p, i1) => {
        const p0 = charts[0][i1]
        const p1 = charts[1][i1]
        const p2 = charts[2][i1]
        const p3 = charts[3][i1]
        if (p0 && p1 && p2 && p3) {
          _.range(charts.length).filter(i => visibles[i]).reverse().reduce((accumulator, channelIndex) => {
            const p = charts[channelIndex][i1]
            const offsetX = (time(p.time) - halfHour.time.from) * 40 / (30 * 60 * 1000)
            const y = _.first(p.sss).trigger
            a[channelIndex].push(offsetX + halfHour.pixels.from, accumulator - y)
            return accumulator - y
          }, height)
        }
      })
      _.range(charts.length).filter(i => visibles[i]).reverse().forEach(channelIndex => {
        const b = a[channelIndex]
        drawCurve(gsLocal[channelIndex], b, [_.first(b), height], [b[b.length - 2], height], 0.5, COLOR_NUMBERS[channelIndex], 1)
      })
    })
    /*
        _.range(4).filter(i => visibles[i]).reverse().forEach(channelIndex => {
          const a = myPointsC[channelIndex]
          drawCurve(gsLocal[channelIndex], a, [_.first(a), height], [a[a.length - 2], height], 0.5, COLOR_NUMBERS[channelIndex], 1)
        })
    */
  })

  if (dailyChartModel.halfHours.length > 0) {
    gChartBackgroundLocal.with(NAVIGATION_DEFAULT_BACKGROUND_COLOR, 1, g =>
      g.drawRect(_.last(dailyChartModel.halfHours).pixels.to + 1, 0, dailyChartModel.chartWidth, height), ALPHA, 0)
  }

  /*
    const myPoints = [
      10, 50,
      40, 30,
      100, 10,
      150, 50,
      200, 50,
      250, 50]
    drawCurve(gChartBackground, myPoints, [10, 70], [250, 70], 0.5, 0x00ff00, 1)
  */
}

const NavigationChartCanvas = PixiComponent<NavigationChartCanvasProps, PIXI.Graphics>('NavigationChartCanvas',
  {
    create: () => new PIXI.Graphics(),
    applyProps: (gMain, _oldProps, {
      height,
      dailyChartModel,
      visibles,
      chosenHalfHourIndex,
      mouseHalfHourIndex,
    }) => {
      gs.map(g => g.clear())
      gChartBackground.clear()
      gMain.clear()

      gMain.beginFill(NAVIGATION_DEFAULT_BACKGROUND_COLOR, ALPHA)
      drawChartsWithBackground(gChartBackground, gs, dailyChartModel, height, 10, 0, visibles, chosenHalfHourIndex, mouseHalfHourIndex)
      gMain.addChildAt(gChartBackground, 0)
      gs.forEach((g, i) =>
        gMain.addChildAt(g, i + 1))
      gMain.endFill()
    },
  },
)

interface INavigationChartProps {
  width: number
  height: number
  visibles: [boolean, boolean, boolean, boolean]
  onChoseHalfHour: (chosenHalfHourIndex: number) => void
  date: Date
  dailyChartModel: DailyChartModel
  isMobile: boolean
}

interface INavigationChartState {
  chosenHalfHourIndex: number
  mouseHalfHourIndex: number
  scrollX: number
  deltaX: number
  wasSwiping: boolean
  isSwiping: boolean
  isOnNavigationChart: boolean
}

const textStyle: React.CSSProperties = {
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 9,
  lineHeight: '100%',
  color: '#ffffff',
}

class NavigationChart extends React.Component<INavigationChartProps, INavigationChartState> {
  constructor(props: any) {
    super(props)

    this.state = {
      chosenHalfHourIndex: 0,
      mouseHalfHourIndex: null,
      scrollX: 0,
      deltaX: 0,
      wasSwiping: false,
      isSwiping: false,
      isOnNavigationChart: false,
    }
  }

  /*
    public componentDidMount = () =>
      document.addEventListener("wheel", e => {
        if (!this.state.isOnNavigationChart)
          return
        this.setState(prevState => ({...prevState, scrollX: _.clamp(prevState.scrollX + e.deltaY * 10, 0, this.props.chart Width)}))
        e.preventDefault()
      }, {passive: false})
  */

  public onClickHandler = (e: any): void => {
    const {nativeEvent} = e
    const limitedScrollX = _.clamp(nativeEvent.offsetX, PADDING, this.props.dailyChartModel.chartWidth - PADDING)
    const chosenHalfHourIndex = this.props.dailyChartModel.getHalfHourIndexByX(limitedScrollX)
    this.chosenScroll(chosenHalfHourIndex)
  }

  public onTouchEndHandler = (e: any): void => {
    if (this.state.wasSwiping)
      return
    const left = e.changedTouches[0].pageX - e.currentTarget.getBoundingClientRect().left
    const limitedLeft = _.clamp(left, PADDING, this.props.dailyChartModel.chartWidth - PADDING)
    const chosenHalfHourIndex = this.props.dailyChartModel.getHalfHourIndexByX(limitedLeft)
    this.chosenScroll(chosenHalfHourIndex)
  }

  public onTouchStartHandler = (_e: any): void =>
    this.setState(prevState => ({...prevState, wasSwiping: false}))

  public chosenScroll = (chosenHalfHourIndex: number): void => {
    const chosenHalfHourIndexLimited =
      chosenHalfHourIndex < 0
        ? this.state.chosenHalfHourIndex
        : chosenHalfHourIndex
    if (this.state.chosenHalfHourIndex !== chosenHalfHourIndexLimited)
      this.props.onChoseHalfHour(chosenHalfHourIndexLimited)
    this.setState(prevState => ({
      ...prevState,
      scrollX: this.props.dailyChartModel.halfHours.length > 0
        ? _.clamp(this.props.dailyChartModel.halfHours[chosenHalfHourIndexLimited].pixels.from - PADDING, 0, this.props.dailyChartModel.chartWidth - 2 * PADDING)
        : 0,
      chosenHalfHourIndex: chosenHalfHourIndexLimited,
    }))
  }

  public onMouseMoveHandler = (e: any): void => {
    const {nativeEvent} = e
    this.setState(prevState => ({...prevState, mouseHalfHourIndex: this.props.dailyChartModel.getHalfHourIndexByX(nativeEvent.offsetX)}))
  }

  public onMouseLeaveHandler = (_e: any): void =>
    this.setState(prevState => ({...prevState, mouseHalfHourIndex: null}))

  public onSwipingHandler = (dailyChartModel: DailyChartModel): any =>
    (e: any): void => {
      const limitedValue = _.clamp(this.state.scrollX + PADDING + e.deltaX, PADDING, dailyChartModel.chartWidth - PADDING)
      const chosenHalfHourIndexInit = dailyChartModel.getHalfHourIndexByX(limitedValue)
      const chosenHalfHourIndexLimited =
        chosenHalfHourIndexInit < 0
          ? this.state.chosenHalfHourIndex
          : chosenHalfHourIndexInit
      if (this.state.chosenHalfHourIndex !== chosenHalfHourIndexLimited)
        this.props.onChoseHalfHour(chosenHalfHourIndexLimited)
      this.setState(prevState => ({...prevState, chosenHalfHourIndex: chosenHalfHourIndexLimited, deltaX: e.deltaX, isSwiping: true}))
    }
  public onSwipedHandler = (dailyChartModel: DailyChartModel): any =>
    (): void => this.setState(prevState => ({
      ...prevState,
      scrollX: _.clamp(dailyChartModel.halfHours[prevState.chosenHalfHourIndex].pixels.from - PADDING, 0, dailyChartModel.chartWidth - 2 * PADDING),
      deltaX: 0,
      wasSwiping: true,
      isSwiping: false,
    }))

  public onScrollHandler = (e: any): void => {
    if (!this.state.isSwiping)
      this.setState(prevState => ({...prevState, scrollX: e.scrollLeft}))
  }

  public onMouseHandler = (isOnNavigationChart: boolean): any =>
    (_e: any): void =>
      this.setState(prevState => ({...prevState, isOnNavigationChart}))

  public render(): React.ReactNode {
    const {height, visibles, dailyChartModel, isMobile, width} = this.props
    const {mouseHalfHourIndex, chosenHalfHourIndex, deltaX} = this.state
    const timeLabelWidth = 33

    let saveXByDateFrom: number = null
    return <Swipeable
      onSwiping={this.onSwipingHandler(dailyChartModel)}
      onSwiped={this.onSwipedHandler(dailyChartModel)}
      delta={30}>
      <Scrollbar
        style={{height: 84}}
        trackXProps={{style: {height: 6, width: PANEL_WIDTH - 2, marginLeft: -10, background: 'none'}}}
        scrollLeft={this.state.scrollX + deltaX}
        onScroll={this.onScrollHandler}
        onMouseEnter={this.onMouseHandler(true)}
        onMouseLeave={this.onMouseHandler(false)}
        noScrollY={true}
        noScrollX={isMobile || dailyChartModel.halfHours.length === 0 || _.last(dailyChartModel.halfHours).pixels.to <= width}>
        <div style={{position: 'relative'}}>
          {dailyChartModel.fullCharts.map(navigationChartsWithRange =>
            navigationChartsWithRange.map((navigationChartWithRange, i) => {
              const mw = navigationChartWithRange.chart
              const dateTime = new Date(mw.range.from)
              const hours = dateTime.getHours().toString().padStart(2, '0')
              const minutes = dateTime.getMinutes().toString().padStart(2, '0')
              const fromPixels = navigationChartWithRange.range.pixels.from
              if (saveXByDateFrom && navigationChartWithRange.range.pixels.from < saveXByDateFrom + timeLabelWidth)
                return ''
              saveXByDateFrom = fromPixels
              return <span key={i} style={{
                ...textStyle,
                position: 'absolute',
                float: 'left',
                top: 10,
                left: fromPixels - timeLabelWidth / 2,
                backgroundColor: 'rgba(17,17,17,0.7)',
                borderRadius: 8,
                height: 15,
                width: timeLabelWidth,
                padding: '3px 4px',
              }}>{`${hours}:${minutes}`}</span>
            }))}
        </div>
        <Stage
          width={dailyChartModel.chartWidth}
          height={height}
          options={{backgroundColor: NAVIGATION_DEFAULT_BACKGROUND_COLOR, antialias: true}}
          // onTouchStart={e => {
          //   const nativeEvent = e.nativeEvent
          //   this.clientX = _.first(nativeEvent.touches).clientX
          // }}
          // onTouchMove={e => {
          //   const nativeEvent = e.nativeEvent
          //   const deltaX = _.first(nativeEvent.touches).clientX - this.clientX
          //   this.clientX = _.first(nativeEvent.touches).clientX
          //   this.setState(prevState => ({...prevState, scrollX: _.clamp(prevState.scrollX - deltaX, 0, chartWidth)}))
          // }}
          onTouchStart={this.onTouchStartHandler}
          onTouchEnd={this.onTouchEndHandler}
          onClick={this.onClickHandler}
          onMouseMove={this.onMouseMoveHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          style={{borderBottom: '1px solid #e4e4e4'}}>
          <NavigationChartCanvas
            height={height}
            dailyChartModel={dailyChartModel}
            visibles={visibles}
            chosenHalfHourIndex={chosenHalfHourIndex}
            mouseHalfHourIndex={mouseHalfHourIndex}/>
        </Stage>
      </Scrollbar>
    </Swipeable>
  }
}

export default NavigationChart