/* eslint-disable */
import * as React from 'react'
import i18next from 'i18next'
import {languageCode, NEUROBOOST} from '../common/common'
import '../style.css'
import classNames from 'classnames'

type InstallAppBadgeProps = { isMobile: boolean; mobileVisible: boolean; hide: any }
const InstallAppBadge = (properties: InstallAppBadgeProps): any =>
  properties.isMobile
    ? properties.mobileVisible &&
    <div className='google-mobile'>
      <img className='google-close' src='/close.svg' alt='' onClick={properties.hide}/>
      <div className={classNames('flex', 'google-mobile-content')}>
        <img className='google-img' src='/app-icon.svg' alt=''/>
        <div>{NEUROBOOST}<br/><span>{i18next.t('on_google_play')}</span></div>
      </div>
      <a href='https://play.google.com/store/apps/details?id=com.knwlab.neuroboost'>
        <button className='google-install'>{i18next.t('install')}</button>
      </a>
    </div>
    : <div className='google-play'>
      <div className='google-play-title'>
        {i18next.t('get_the_app_to_monitor_your_health')}
      </div>
      <a href='https://play.google.com/store/apps/details?id=com.knwlab.neuroboost'>
        <img className='google-play-image' src={`/google-play-badge.${languageCode()}.png`} alt=''/>
      </a>
    </div>

export default InstallAppBadge