import {EventDataPoint, MentalWaves} from '../../../client/client.generated'
import {center, CommonRange, isIntersect, isIntersectInclusive, time, width} from '../../../common/common'
import * as _ from 'lodash'
import {HALF_HOUR_TIME, HALF_HOURS, PANEL_WIDTH} from './common'

export const HALF_HOUR_WIDTH = 40

const HALF_HOUR_TIME_COEFFICIENT = HALF_HOUR_WIDTH / HALF_HOUR_TIME
export const PADDING = (PANEL_WIDTH - HALF_HOUR_WIDTH) / 2

const COMPRESSED_EMPTY_WIDTH = 10

class NavigationChartRange {
  pixels: CommonRange
  time: CommonRange

  constructor(fromPixels: number, toPixels: number, fromTime: number, toTime: number) {
    this.pixels = {from: fromPixels, to: toPixels} as CommonRange
    this.time = {from: fromTime, to: toTime} as CommonRange
  }
}

export class NavigationChartWithRange {
  range: NavigationChartRange
  chart: MentalWaves
}

const getAdjoiningFullChartIndexes = (halfHours: NavigationChartRange[]): number[][] =>
  _.range(halfHours.length).slice(1).reduce((acc, halfHourIndex) =>
    halfHours[halfHourIndex].time.from - halfHours[halfHourIndex - 1].time.from > 3600 * 1000
      ? acc.concat([[halfHourIndex]])
      : [..._.initial(acc), [..._.last(acc), halfHourIndex]], [[0]])

export class DailyChartModel {
  halfHours: NavigationChartRange[] = new Array<NavigationChartRange>()
  fullCharts: NavigationChartWithRange[][] = new Array<NavigationChartWithRange[]>()

  // [[0, 1, 2], [3, 4]]
  adjoiningFullChartIndexes: number[][]

  // [*, *, *, ╎  *,  *]
  // ┌┴──┴──┘  ╎ ┌┴───┘
  // Charts A  ╎ Charts B
  adjoiningFullChartsByHalfHourIndex: NavigationChartWithRange[][]

  halfHourCharts: NavigationChartWithRange[][] = new Array<NavigationChartWithRange[]>()
  halfHourChartAverages: number[][][] = new Array<number[][]>()
  chartWidth: number

  constructor(data: MentalWaves[]) {
    if (data && data.length > 0) {
      const firstFrom = new Date(_.first(data).range.from)
      const d = new Date(firstFrom.getFullYear(), firstFrom.getMonth(), firstFrom.getDate())

      let left = PADDING
      let previousIndex = null
      _.range(HALF_HOURS).forEach(halfHourIndex => {
        const halfHourFromDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 30 * halfHourIndex)
        const halfHourToDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 30 * (halfHourIndex + 1))
        const halfHourFromTime = halfHourFromDate.getTime()
        const halfHourToTime = halfHourToDate.getTime()
        if (!data.some(mw => isIntersect(new Date(mw.range.from), new Date(mw.range.to), halfHourFromDate, halfHourToDate)))
          return null

        if (previousIndex !== null && previousIndex < halfHourIndex - 1)
          left += COMPRESSED_EMPTY_WIDTH

        // Add full charts
        const currentHalfHourFullCharts = data.filter(mw => halfHourFromDate <= new Date(mw.range.from) && new Date(mw.range.from) <= halfHourToDate)
        this.fullCharts.push(currentHalfHourFullCharts.map(mw => {
          const chartFromTime = time(mw.range.from)
          const chartToTime = time(mw.range.to)
          const offsetFromX = (chartFromTime - halfHourFromTime) * HALF_HOUR_TIME_COEFFICIENT
          const offsetToX = (chartToTime - halfHourFromTime/* (sic!) */) * HALF_HOUR_TIME_COEFFICIENT
          return {
            range: new NavigationChartRange(left + offsetFromX, left + offsetToX, chartFromTime, chartToTime),
            chart: mw,
          }
        }))

        // Add half hour charts
        this.halfHourCharts.push(data.filter(mw => isIntersectInclusive(time(mw.range.from), time(mw.range.to), halfHourFromTime, halfHourToTime)).map(mw => {
          const chartFromTime = time(mw.range.from)
          const chartToTime = time(mw.range.to)
          const clippedFromTime = Math.max(chartFromTime, halfHourFromTime)
          const clippedToTime = Math.min(chartToTime, halfHourToTime)
          const offsetFromX = (clippedFromTime - halfHourFromTime) * HALF_HOUR_TIME_COEFFICIENT
          const offsetToX = (clippedToTime - halfHourFromTime) * HALF_HOUR_TIME_COEFFICIENT
          return {
            range: new NavigationChartRange(left + offsetFromX, left + offsetToX, clippedFromTime, clippedToTime),
            chart: {
              range: mw.range,
              concentration: mw.concentration.filter(x => chartFromTime <= time(x.time) && time(x.time) <= chartToTime),
              cognitiveLoad: mw.cognitiveLoad.filter(x => chartFromTime <= time(x.time) && time(x.time) <= chartToTime),
              flow: mw.flow.filter(x => chartFromTime <= time(x.time) && time(x.time) <= chartToTime),
              stress: mw.stress.filter(x => chartFromTime <= time(x.time) && time(x.time) <= chartToTime),
            } as MentalWaves,
          }
        }))

        // Add half hour
        this.halfHours.push(new NavigationChartRange(left, left + HALF_HOUR_WIDTH, halfHourFromTime, halfHourToTime))

        previousIndex = halfHourIndex
        left += HALF_HOUR_WIDTH
      })
      this.chartWidth = left + PADDING
    }

    const millisecondsPerPixel = 1000 * 60 * 30 / HALF_HOUR_WIDTH
    this.halfHourChartAverages = this.halfHours.map((halfHour, halfHourIndex) =>
      _.range(HALF_HOUR_WIDTH).map(xOffset => {
        const navPointFrom = halfHour.time.from + xOffset * millisecondsPerPixel
        const navPointTo = halfHour.time.from + (xOffset + 1) * millisecondsPerPixel

        const concentrations = this.halfHourCharts[halfHourIndex].flatMap(mw => mw.chart.concentration)
        const cognitiveLoads = this.halfHourCharts[halfHourIndex].flatMap(mw => mw.chart.cognitiveLoad)
        const flows = this.halfHourCharts[halfHourIndex].flatMap(mw => mw.chart.flow)
        const stresses = this.halfHourCharts[halfHourIndex].flatMap(mw => mw.chart.stress)
        const mw4: EventDataPoint[][] = [concentrations, cognitiveLoads, flows, stresses]

        return _.range(mw4.length).map(channelIndex =>
          _.meanBy(mw4[channelIndex].filter(p => navPointFrom <= time(p.time) && time(p.time) <= /* sic! */ navPointTo), p => _.first(p.sss).trigger))
      }))

    this.adjoiningFullChartIndexes = getAdjoiningFullChartIndexes(this.halfHours)
    this.adjoiningFullChartsByHalfHourIndex = this.adjoiningFullChartIndexes.flatMap(indexes =>
      Array(indexes.length).fill(this.fullCharts.slice(_.first(indexes), _.last(indexes) + 1).flat()))
  }

  public getHalfHourIndexByX = (x: number): number =>
    this.halfHours.findIndex(halfHour => halfHour.pixels.from <= x && x <= halfHour.pixels.to)

  public chosenCenterByIndex = (chosenHalfHourIndex: number, viewWidth: number): number => {
    const adjoiningFullCharts = this.adjoiningFullChartsStartEndTimeRange(chosenHalfHourIndex)
    const maxWidthChart = _.maxBy(this.halfHourCharts[chosenHalfHourIndex], x => x.range.time.to - x.range.time.from)
    return (center(maxWidthChart.range.time) - adjoiningFullCharts.from) * viewWidth / HALF_HOUR_TIME - viewWidth / 2
  }

  public adjoiningFullChartsStartEndTimeRange = (adjoiningFullChartsIndex: number): CommonRange => {
    const adjoiningFullChartIndexes = this.adjoiningFullChartIndexes.find(xs => xs.some(x => x === adjoiningFullChartsIndex))
    const firstIndex = _.first(adjoiningFullChartIndexes)
    const lastIndex = _.last(adjoiningFullChartIndexes)
    const firstHalfHour = this.halfHours[firstIndex]
    const lastHalfHour = this.halfHours[lastIndex]
    const firstHalfHourCharts = this.halfHourCharts[firstIndex]
    const firstHalfHourChart = _.first(firstHalfHourCharts)
    const lastHalfHourCharts = this.halfHourCharts[lastIndex]
    const lastHalfHourChart = _.last(lastHalfHourCharts)
    const firstHalfHourChartMax = _.maxBy(firstHalfHourCharts, x => width(x.range.time))
    const lastHalfHourChartMax = _.maxBy(lastHalfHourCharts, x => width(x.range.time))

    if (firstHalfHourCharts == null || firstHalfHourCharts.length === 0)
      return null
    const fromChartTime = _.first(firstHalfHourCharts).range.time.from
    const toChartTime = _.last(lastHalfHourCharts).range.time.to
    const timeWidth = toChartTime - fromChartTime

    const startTimeForCenterOfMax = firstHalfHour.time.from + center(firstHalfHourChartMax.range.time) - center(firstHalfHour.time)
    const endTimeForCenterOfMax = lastHalfHour.time.to + center(lastHalfHourChartMax.range.time) - center(lastHalfHour.time)

    return timeWidth < HALF_HOUR_TIME
      ? {
        from: fromChartTime,
        to: toChartTime,
      }
      : {
        from: Math.min(startTimeForCenterOfMax, firstHalfHourChart.range.time.from),
        to: Math.max(endTimeForCenterOfMax, lastHalfHourChart.range.time.to),
      }
  }

  public isLastChartIndex = (index: number): boolean =>
    this.adjoiningFullChartIndexes.length - 1 === this.adjoiningFullChartIndexes.findIndex((value, _index, _obj) => value.some(x => x === index))
}