/* eslint-disable */
import 'react'
import i18next from 'i18next'
import {Dialog} from '@material-ui/core'
import * as classNames from 'classnames'
import {BLUE_STRING, GREEN_STRING, ORANGE_STRING, RED_STRING} from './common'
import {FC} from 'react'
import React from 'react'

const IndicatorsHelpDialog: FC<any> = (props: any): any =>
  <Dialog
    {...props}
    PaperProps={{style: {margin: 0, maxWidth: 'none', width: props.width, minWidth: props.width, borderRadius: 10}}}>
    <div className={classNames('modal-inner', 'help')}>
      <div className='modal-top'>
        <img className='modal-close' src='/close.svg' alt='' onClick={props.onClose}/>
        <h2>{i18next.t('what_are_these_indicators')}</h2>
      </div>
      <div className={classNames('modal-body', 'help')}>
        <table>
          <thead>
          <tr>
            <td colSpan={3} style={{padding: '10px 46px 28px  '}}>{i18next.t('help_intro')}</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td rowSpan={3} className='help-image'>
              <img src='/concentration.svg' alt='Concentration'/>
              <div className={classNames('flex', 'filter-item', 'blue', 'indicator-title')}>
                {i18next.t('concentration')}
              </div>
            </td>
            <td colSpan={2}>
              <div className='modal-item-text'>{i18next.t('concentration_description_top')}</div>
            </td>
          </tr>
          <tr>
            <td style={{width: '45%'}}>
              <div style={{color: BLUE_STRING}}>
                <div className='level-digits'>7-10</div>
                <div className='modal-item-text' style={{color: BLUE_STRING}}>{i18next.t('concentration_description_7_10')}</div>
              </div>
            </td>
            <td>
              <div style={{color: '#b0b0b0'}}>
                <div className='level-digits'>1-4</div>
                <div className='modal-item-text' style={{color: '#b0b0b0'}}>{i18next.t('concentration_description_1_4')}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className='modal-item-text'>
                {i18next.t('concentration_description_bottom_1')}
                <a href='http://tabshulte.4xpro.ru'>{i18next.t('concentration_description_bottom_link')}</a>
                {i18next.t('concentration_description_bottom_2')}</div>
            </td>
          </tr>

          <tr>
            <td rowSpan={3} className='help-image'>
              <img src='/cognitive-load.svg' alt='Cognitive load'/>
              <div className={classNames('flex', 'filter-item', 'red', 'indicator-title')}>
                {i18next.t('cognitive_load')}
              </div>
            </td>
            <td colSpan={2}>
              <div className='modal-item-text'>{i18next.t('cognitive_load_description_top')}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{color: RED_STRING}}>
                <div className='level-digits'>7-10</div>
                <div className='modal-item-text' style={{color: RED_STRING}}>{i18next.t('cognitive_load_description_7_10')}</div>
              </div>
            </td>
            <td>
              <div style={{color: '#b0b0b0'}}>
                <div className='level-digits'>1-4</div>
                <div className='modal-item-text' style={{color: '#b0b0b0'}}>{i18next.t('cognitive_load_description_1_4')}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className='modal-item-text'>
                {i18next.t('cognitive_load_description_bottom_1')}
                <a href='http://ru.brainexer.com'>{i18next.t('cognitive_load_description_bottom_link')}</a>
                {i18next.t('cognitive_load_description_bottom_2')}
                <a href='https://rpadgett.butler.edu/nw221/sternberg_lab/index.html'>{i18next.t('cognitive_load_description_bottom_link')}</a>
                {i18next.t('cognitive_load_description_bottom_3')}
              </div>
            </td>
          </tr>

          <tr>
            <td rowSpan={3} className='help-image'>
              <img src='/stress.svg' alt='Stress'/>
              <div className={classNames('flex', 'filter-item', 'orange', 'indicator-title')}>
                {i18next.t('stress')}
              </div>
            </td>
            <td colSpan={2}>
              <div className='modal-item-text'>{i18next.t('cognitive_load_description_top')}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{color: ORANGE_STRING}}>
                <div className='level-digits'>7-10</div>
                <div className='modal-item-text' style={{color: ORANGE_STRING}}>{i18next.t('stress_description_7_10')}</div>
              </div>
            </td>
            <td>
              <div style={{color: '#b0b0b0'}}>
                <div className='level-digits'>1-4</div>
                <div className='modal-item-text' style={{color: '#b0b0b0'}}>{i18next.t('stress_description_1_4')}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className='modal-item-text'>{i18next.t('stress_description_bottom')}</div>
            </td>
          </tr>

          <tr>
            <td rowSpan={3} className='help-image'>
              <img src='/flow.svg' alt='Flow'/>
              <div className={classNames('flex', 'filter-item', 'green', 'indicator-title')}>
                {i18next.t('flow')}
              </div>
            </td>
            <td colSpan={2}>
              <div className='modal-item-text'>{i18next.t('cognitive_load_description_top')}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{color: GREEN_STRING}}>
                <div className='level-digits'>7-10</div>
                <div className='modal-item-text' style={{color: GREEN_STRING}}>{i18next.t('flow_description_7_10')}</div>
              </div>
            </td>
            <td>
              <div style={{color: '#b0b0b0'}}>
                <div className='level-digits'>1-4</div>
                <div className='modal-item-text' style={{color: '#b0b0b0'}}>{i18next.t('flow_description_1_4')}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className='modal-item-text'>{i18next.t('flow_description_bottom')}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Dialog>

export default IndicatorsHelpDialog