export const NEUROBOOST = 'Neuroboost'

export const inRange = <T extends {}>(x: T, from: T, to: T): boolean =>
  from <= x && x < to

export const inRangeInclusive = <T extends {}>(x: T, from: T, to: T): boolean =>
  inRange(x, from, to) || x === to

export const isIntersectInclusive = <T extends {}>(from1: T, to1: T, from2: T, to2: T): boolean =>
  inRangeInclusive(from1, from2, to2) ||
  inRangeInclusive(from2, from1, to1)

export const isIntersect = <T extends {}>(from1: T, to1: T, from2: T, to2: T): boolean =>
  inRange(from1, from2, to2) ||
  inRange(from2, from1, to1)

// eslint-disable-next-line no-shadow
export class CommonRange {
  from: number
  to: number
}

export const center = (range: CommonRange): number =>
  (range.from + range.to) / 2

export const width = (range: CommonRange): number =>
  range.to - range.from

export const date = (x: Date | number): Date =>
  new Date(x)

// Fix Date 'is not a function' error
export const time = (x: Date | number): number =>
  date(x).getTime()

export const isMobile = (): boolean =>
  /mobi/i.test(window.navigator.userAgent)

export const isRussian = (): boolean =>
  navigator.language.toLocaleLowerCase().startsWith('ru')

export const languageCode = (): 'ru' | 'en' =>
  isRussian()
    ? 'ru'
    : 'en'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const empty = (): void => {
}

export const hasAssertions = (callback: any): void => {
  expect.hasAssertions()
  callback()
}